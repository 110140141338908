<template>
  <div>
    <div
      style="
        font-size: 0.416rem;
        padding: 0.3rem;
        line-height: 200%;
        background: #f5f5f5;
      "
      v-html="content"
    ></div>
    <div style="height: 3rem; overflow: hidden">
      <div
        style="
          position: fixed;
          bottom: 0;
          background: #fff;
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          font-size: 0.4rem;
        "
      >
        <van-checkbox v-model="checked" shape="square">
          我已知晓
          <span style="color: rgb(255, 140, 0)">《{{ title }}》</span>
        </van-checkbox>
        <br />
        <van-button
          block
          round
          type="info"
          :disabled="!checked"
          @click="toInfo"
        >
          我已知晓
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { getMessageInfo } from '@/api/message/message'

  export default {
    data() {
      return {
        checked: false,
        sign: '',
        content: '',
        title: '',
      }
    },
    created() {
      this.sign = this.$route.query.sign
      switch (this.sign) {
        case 'ghyy': //挂号预约
          this.title = '挂号预约须知'
          break

        case 'gzrgl': //就诊人管理
          this.title = '就诊人管理须知'
          break

        case 'blfy':
          this.title = '病例复印须知'
          break

        case 'cwyy':
          this.title = '床位预约须知'
          break
      }
      this.fetchData()
    },

    methods: {
      async fetchData() {
        const { data } = await getMessageInfo({ Sign: this.sign })
        this.content = data.content
      },

      toInfo() {
        switch (this.sign) {
          case 'ghyy': //挂号预约
            this.$router.push('/index')
            break

          case 'gzrgl': //就诊人管理
            this.$router.push('/account')
            break

          case 'blfy':
            this.$router.push('/caseCopyAdd')
            break

          case 'cwyy':
            this.$router.push('/bed')
            break

          default:
            this.$router.push('/index')
            break
        }
      },
    },
  }
</script>
